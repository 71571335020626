.wrapper {
}

.children {
  overflow: hidden;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.titleSub {
  flex: 1;
}

.carret {
  margin-right: 12px;
  transform: rotate(-90deg);
  transition: transform 0.1s;
  cursor: pointer;
  height: 10px;
  width: 10px;
}

.carretIcon {
  width: 10px;
  height: 10px;
  mask-size: contain;
  display: block;
}

.indented .children {
  padding-left: 36px;
}

.expanded > .title .carret {
  transform: rotate(0);
}

.tight > .title .carret {
  margin-right: 8px;
}
