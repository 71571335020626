.wrapper {
  margin: 0 40px 2rem;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  background: var(--lightestGrey);
  padding-right: 1rem;
  border-radius: 10px;
  align-items: center;
}

.input {
  flex: 1;
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  font: inherit;
  font-size: 15px;
  padding: 1.5rem 0.5rem 1.5rem 2rem;
  line-height: 1.5em;
}

@media (max-width: 939px) {
  .wrapper {
    margin: 0 1rem 1rem;
  }
}
