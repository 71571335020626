.wrapper {
}

.innerWrapper {
}

.json {
  white-space: pre-wrap;
  font-family: var(--fixed);
  font-size: 13px;
}

.null {
  color: var(--midGrey);
  font-style: italic;
}

.link {
  color: var(--primary);
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}
