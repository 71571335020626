.table {
  padding: 40px 40px 60px;
}

.assistant {
  padding-bottom: 0;
}

.header {
  position: sticky;
  top: 60px;
  height: 40px;
  background: var(--white);
  font-weight: 500;
  color: var(--midGrey);
  text-transform: capitalize;
}

.assistant .header {
  top: -1rem;
}

.header,
.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: -40px;
  padding-left: 40px;
  box-shadow: #fff 40px 0, 40px 1px var(--hairline);
  margin-bottom: 1px;
}

.cell {
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 0.5rem;
  border-right: 1px solid var(--hairline);
}

.body {
}

.headerCell {
  position: relative;
}

.resizeHandle {
  cursor: col-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -6px;
  width: 13px;
  z-index: 1;
}

@media (max-width: 939px) {
  .table {
    padding: 0 1rem;
  }
}
