.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  height: 60px;
  margin-top: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background: var(--white);
  box-shadow: 0 1px var(--hairlineTransparent);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem calc(40px + 2rem);
  overflow-y: auto;
}

.messagesInner {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message {
  margin: 1rem 0;
  font-size: 15px;
}

.userMessage {
  background: var(--lightestGrey);
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  align-self: flex-end;
}

.assistantMessage + .assistantMessage,
.userMessage + .userMessage {
  margin-top: 0;
}

.query {
  /*align-self: flex-start;*/
}

.footerRow {
  color: var(--midGrey);
}

.sql {
  margin: 0.5rem 0 30px 40px;
  padding-left: 0.5rem;
}

.sqlDetail {
  margin-left: 0;
  background: var(--lightestGrey);
  font-size: 15px;
  padding: 1rem;
}

.sqlTitle {
  color: var(--midGrey);
}

.sqlExpanded .icon {
  transform: rotate(-180deg);
}

.assistantMessage > * + * {
  margin-top: 1rem;
}

.assistantMessage pre {
  background: var(--lightestGrey);
  padding: 1rem;
}

.assistantMessage p code {
  background: var(--lightestGrey);
  color: var(--red);
}

.count {
  margin: 0 0 0 calc(40px + 0.5rem);
  padding: 0.25rem 0;
  color: var(--midGrey);
}

.error {
  border: 1px solid var(--red);
  background: var(--redAlpha10);
  padding: 1rem;
  margin: 1rem 40px;
  align-self: flex-start;
}

.sqlTitle a {
  color: inherit;
  font-weight: normal;
}

.createView {
  margin-left: 1rem;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 1rem 0 1rem 2rem;
  gap: 0.5rem;
  font-weight: 500;
  color: var(--midGrey);
}

.preAccordion {
  margin-left: 160px;
  line-height: 32px;
  margin-bottom: -34px;
  position: relative;
  z-index: 1;
  pointer-events: none;
  color: var(--midGrey);
}

.preAccordion > * {
  pointer-events: auto;
}

.preAccordion a {
  color: var(--midGrey);
  text-decoration: none;
  cursor: pointer;
}

.preAccordion a:hover {
  text-decoration: underline;
}

@media (max-width: 939px) {
  .header {
    margin-top: 0;
  }

  .messages {
    padding: 1rem 1rem;
  }

  .sql {
    margin-left: 1rem;
  }

  .sqlDetail {
    padding: 0.5rem;
  }

  .count {
    margin-left: 24px;
  }
}
