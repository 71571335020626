.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-type: alpha;
  mask-repeat: no-repeat;
  mask-position: center;
  background: #333;
}

.big {
  width: 28px;
  height: 28px;
  mask-size: contain;
}

.xl {
  width: 48px;
  height: 48px;
  mask-size: contain;
}

.tight,
.inline {
  margin: -6px -4px;
}

.inline {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.placeholder,
.icon-placeholder {
  background: transparent !important;
  mask: none;
}

/* Colors */
.color-grey {
  background-color: var(--midGrey);
}

.color-lightGrey {
  background-color: #bbb;
}

.color-blue,
.color-primary {
  background-color: var(--primary);
}

.color-red {
  background-color: var(--red);
}

.color-white {
  background-color: #fff;
}

.color-yellow {
  background-color: var(--yellow);
}

/* Icons */

.icon-add {
  mask-image: url('./icons/add.svg');
}

.icon-run {
  mask-image: url('./icons/run.svg');
}

.icon-carret,
.icon-expand,
.icon-collapse {
  mask-image: url('./icons/carret.svg');
  transition: transform 0.2s;
}

.icon-expand {
  transform: rotate(-90deg);
}

.icon-collapse-up {
  transform: rotate(90deg);
}

.icon-carret-filled {
  mask-image: url('./icons/carret-filled.svg');
}

.icon-edit {
  mask-image: url('./icons/edit.svg');
}

.icon-delete {
  mask-image: url('./icons/delete.svg');
}

.icon-checkmark {
  mask-image: url('./icons/checkmark.svg');
}

.icon-checkmark-naked {
  mask-image: url('./icons/checkmark-naked.svg');
}

.icon-checkmark-naked-small {
  mask-image: url('./icons/checkmark-naked-small.svg');
}

.icon-error {
  mask-image: url('./icons/error.svg');
}

.icon-lightning {
  mask-image: url('./icons/lightning.svg');
}

.icon-search {
  mask-image: url('./icons/search.svg');
}

.icon-overflow {
  mask-image: url('./icons/overflow.svg');
}

.icon-back {
  mask-image: url('./icons/back.svg');
}

.icon-forward {
  mask-image: url('./icons/back.svg');
  transform: rotate(180deg);
}

.icon-link {
  mask-image: url('./icons/link.svg');
}

.icon-settings {
  mask-image: url('./icons/settings.svg');
}

.icon-undo {
  mask-image: url('./icons/undo.svg');
}

.icon-hide {
  mask-image: url('./icons/hide.svg');
}

.icon-question-mark {
  mask-image: url('./icons/question-mark.svg');
}

.icon-question-mark-filled {
  mask-image: url('./icons/question-mark-filled.svg');
}

.icon-more {
  mask-image: url('./icons/more.svg');
}

.icon-alert {
  mask-image: url('./icons/alert.svg');
}

.icon-arrow-up,
.icon-arrow-down {
  mask-image: url('./icons/arrow-up.svg');
}

.icon-arrow-down {
  transform: rotate(180deg);
}

.icon-x-circle {
  mask-image: url('./icons/x-circle.svg');
}

.icon-sparkle {
  mask-image: url('./icons/sparkle.svg');
}

.icon-open-new {
  mask-image: url('./icons/open-new.svg');
}

.icon-lock {
  mask-image: url('./icons/lock.svg');
}

.icon-arrow-small {
  mask-image: url('./icons/arrow-small.svg');
}

.icon-info {
  mask-image: url('./icons/info.svg');
}

.icon-x {
  mask-image: url('./icons/x.svg');
}

.icon-table {
  mask-image: url('./icons/table.svg');
}

.icon-chart {
  mask-image: url('./icons/chart.svg');
}

.icon-folder {
  mask-image: url('./icons/folder.svg');
}

.icon-reload {
  mask-image: url('./icons/reload.svg');
}
